<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Verification" icon="address-card" />

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 mb-3">
                 <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="#">User</a>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'BOEKycList'}">
                            <div class="text-secondary nav-link">Entity</div>    
                        </router-link>
                    </li>
                </ul> 
            </div>

            <div class="col-12 col-lg-4 pb-3">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Email">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <!-- Large screen -->
            <div class="col-12 col-lg-8 text-primary text-end isLink mb-3 d-none d-md-block pt-2">
                <span class="filter-pill-border me-2">
                    <span class="filter-item-pill" :class="filterStatus === 'ALL' ? 'active' : ''" @click="filterStatus = 'ALL'">All</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'SUBMITTED' ? 'active' : ''" @click="filterStatus = 'SUBMITTED'">SUBMITTED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'">PROCESS</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'APPROVED' ? 'active' : ''" @click="filterStatus = 'APPROVED'">APPROVED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">REJECTED</span>
                </span>
            </div>

            <!-- Small screen -->
            <div class="col-12 col-lg-8 text-primary isLink mb-3 d-md-none">
                <select v-model="filterStatus" class="form-select">
                    <option value="ALL">All</option>
                    <option value="SUBMITTED">SUBMITTED</option>
                    <option value="PROCESS">PROCESS</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                </select>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-lg-4 mb-2">
                <table>
                    <tr>
                        <td class="pe-2">From</td>
                        <td><Datepicker v-model="dateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-4">
                <table>
                    <tr>
                        <td class="pe-2">To</td>
                        <td><Datepicker v-model="dateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-4 text-end pt-2 pe-3">
                <span class="text-primary isLink" @click="$router.push({ path: '/backoffice/kyc/report' })">
                    <fa icon="chart-line" class="me-2"/>KYC Report
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>
                            <div v-if="!isLoading" class="mb-3 text-end small fst-italic">{{totalShown}} of {{total}}</div>
            
                            <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                                <span class="mx-2 text-grey isLink">
                                    <fa icon="user-shield" size="lg" style="padding-left: 9px" />
                                </span>
                                <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                            </div>

                            <table class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrDgItem" :key="item">
                                        <td width="50px" class="text-center">
                                            <ImgAvatar :key="itemIdx" :isUser="true" :id="item.jUser.jAvatar.userId" displayName="" :isVerified="item.jUser.isVerified" :isActive="item.jUser.status" privilege="" align="center" width="30" height="30" />
                                        </td>
                                        <td class="align-middle">
                                            <div class="row">
                                                <div class="col-lg-3 fw-bold">
                                                    <div class="isLink">
                                                        <span v-if="item.jUser.fullName"  @click="$router.push('/backoffice/kyc/detail/'+ item.type + '/' + item.jUser.userId)">{{item.jUser.fullName}}</span>
                                                        <span v-else @click="goProfile(item.jUser.userId, item.user.wlId, item.entityId)" class="fst-italic">-</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 small text-grey">
                                                    <span v-if="item.jUser.gender === null">-</span>
                                                    <span v-if="item.jUser.gender === 'M'">Male</span>
                                                    <span v-if="item.jUser.gender === 'F'">Female</span>
                                                </div>
                                                <div class="col-lg-3 small text-grey">{{ item.jUser.userId }}</div>
                                                <div class="col-lg-2 small text-grey">
                                                    <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateCreate, 'datetime')">
                                                        <span class="isTooltipsLabel" style="font-size: 12px;">
                                                            <i>{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateCreate), new Date()) }}</i>
                                                            <!-- <span class="text-danger">{{func.convDateTimeFormat(item.dateCreate, 'date')}}</span> -->
                                                        </span>
                                                    </Popper>
                                                </div>
                                                <div class="col-lg-1 text-grey small">{{item.type}}</div>
                                                <div class="col-lg-2 text-grey small text-end fst-italic">{{item.status}}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgBOKyc from '@/components/ImgBOKyc.vue'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
    name: 'BOKycList',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar, Datepicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const dgMode = ref('all')    // all: load all, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all ORIG: 30
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0)        // record index from
        const rcdDisplay = ref(15)  // total record to display per scroll, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const arrItem = ref([])       // for dgMode=all, different fr arrDgItem
        const arrFilterItem = ref([]) // for dgMode=all only - handle filter and remain orig data in arrItem
        const arrDgItem = ref([])     // record shown in dg, total length shown as totalShown

        const filterName = ref('')
        const filterStatus = ref('ALL')    // PENDING, SUBMITTED,PROCESS,APPROVED,REJECTED
        const dateFr = ref(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 13))
        const dateTo = ref(new Date())
        const totalFilter = ref(0)

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])


        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterName, filterStatus, dateFr, dateTo], () => {

            if (arrItem.value.length > 0) {
                // console.info('- WATCH', dateFr.value, dateTo.value, 'arrItem len:', arrItem.value.length)

                if (dateFr.value === null) {
                    dateFr.value = func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6), 'date')
                }

                if (dateTo.value === null) {
                    dateTo.value = func.convDateTimeFormat(new Date(), 'date')
                }

                if (dgMode.value === 'all') {
                    // console.info('==== ALL')
                    dgAllFilter()
                    proDgAllData()
                }
                else {
                    // console.info('==== PART')
                    rcdFr.value = 0
                    arrDgItem.value = []
                    getList()
                }
            }
        })

        const dgAllFilter = () => {
            // date range filter is compulsory
            // console.info('- func: dgAllFilter', arrItem.value.length)
            // console.info('- func: dgAllFilter', 'Range:', dateFr.value, dateFr.value.length, dateTo.value, dateTo.value.length)

            let arrTmp = []
            let dateCreate = ''
            arrFilterItem.value = []

            if (dateFr.value.length === undefined) {
                dateFr.value = func.convDateTimeFormat(dateFr.value, 'date')
            }

            if (dateTo.value.length === undefined) {
                dateTo.value = func.convDateTimeFormat(dateTo.value, 'date')
            }

            for(var i = 0; i < arrItem.value.length; i++) {
                dateCreate = func.convDateTimeFormat(arrItem.value[i].dateCreate, 'date')
                
                if (dateCreate >= dateFr.value && dateCreate <= dateTo.value) {

                    if (filterName.value.length >= 3 && filterStatus.value !== 'ALL') {
                        // Filter both
                        // console.info('- - - - - Filter: BOTH')
                        if ( arrItem.value[i].jUser.userId.toUpperCase().includes(filterName.value.toUpperCase()) === true && 
                            arrItem.value[i].status.includes(filterStatus.value) === true) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else if (filterName.value.length >= 3 && filterStatus.value === 'ALL') { 
                        if ( arrItem.value[i].jUser.userId.toUpperCase().includes(filterName.value.toUpperCase()) === true && 
                            (arrItem.value[i].status.includes('SUBMITTED') === true || arrItem.value[i].status.includes('PROCESS') === true || 
                             arrItem.value[i].status.includes('APPROVED') === true || arrItem.value[i].status.includes('REJECTED') === true) 
                            ) {
                            arrTmp.push(arrItem.value[i])
                        }

                    } else if (filterName.value.length >= 3) {
                        // Filter name only
                        // console.info('- - - - - Filter: Email Only')
                        if ( arrItem.value[i].jUser.userId.toUpperCase().includes(filterName.value.toUpperCase()) === true) {
                            arrTmp.push(arrItem.value[i])
                        }

                    } else if (filterStatus.value === 'ALL') {
                        // Skipped PENDING status
                        if (arrItem.value[i].status.includes('SUBMITTED') === true || arrItem.value[i].status.includes('PROCESS') === true || 
                             arrItem.value[i].status.includes('APPROVED') === true || arrItem.value[i].status.includes('REJECTED') === true) {
                            arrTmp.push(arrItem.value[i])
                        }

                    } else if (filterStatus.value !== 'ALL') {
                        // filter status only
                        // console.info('- - - - - Filter: Status Only')
                        if ( arrItem.value[i].status.includes(filterStatus.value) === true) {
                            arrTmp.push(arrItem.value[i])
                        }
                    } else {
                        // filter date only
                        // console.info('- - - - - Filter: Date Only')
                        arrTmp.push(arrItem.value[i])
                    }
                }
            } // for


            total.value = arrTmp.length
            totalShown.value = 0
            rcdFr.value = 0

            arrFilterItem.value = arrTmp
            arrDgItem.value = []
        }

        // for dgMode = part, initial access once only
        // for dgModel = all, access when initial access, scroll more, filter
        const proDgAllData = async (datagridMode) => {

            if (datagridMode !== undefined) {
                dgMode.value = datagridMode
            }

            // console.info('- - func: proDgAllData', dgMode.value, datagridMode, typeof datagridMode)

            let len = rcdFr.value + rcdDisplay.value
            let num = 0
            let dateCreate = ''

            if (dgMode.value === 'part') {
                
                // first time only, need filter by date range (compulsory)
                if (dateFr.value.length === undefined) {
                    dateFr.value = func.convDateTimeFormat(dateFr.value, 'date')
                }

                if (dateTo.value.length === undefined) {
                    dateTo.value = func.convDateTimeFormat(dateTo.value, 'date')
                }

                let arrTmp = []
                for (var i = 0; i < arrItem.value.length; i++) {
                    if (arrItem.value[i] !== undefined) {
                        dateCreate = func.convDateTimeFormat(arrItem.value[i].dateCreate, 'date')
                        // console.info('= = = ', dateCreate)
                        if (dateCreate >= dateFr.value && dateCreate <= dateTo.value) {
                            arrTmp.push(arrItem.value[i])
                            if (num < len) {
                                arrDgItem.value.push(arrItem.value[i])
                                num++
                            }
                        }
                    }
                }
                
                arrDgItem.value = arrTmp
                totalShown.value = arrDgItem.value.length
                total.value = arrItem.value.length
                // console.info('- - - - proDgAllData: part', 'arrItem:', arrItem.value.length, 'arrDgItem len:', arrDgItem.value.length)

            } else {
                // loop arrFilterItem due to date filtered is compulsory
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrFilterItem.value[i])
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
            
        }

        // For dgMode = part - to get more list
        const getList = async () => {

            let p = { 
                orderBy: {
                    field: 'dateCreate',
                    order: 'asc'
                },
                dateCreateFrom: dateFr.value + ' 00:00:00',
                dateCreateTo: dateTo.value + ' 23:59:59',
                limit: rcdFr.value + ',' + rcdDisplay.value 
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.userId = filterName.value
            }

            if (filterStatus.value === 'ALL') {
                // skipped PENDING status
                p.status = 'SUBMITTED,PROCESS,APPROVED,REJECTED'
            } 
            else 
            {
                p.status = filterStatus.value
            }

            // console.info('- - - getList p', JSON.stringify(p))
            axios.post('/report/su/user/kyc/list', p)
            .then((res) => {
                // console.info('- -  getList REQ', JSON.stringify(p), 'RES: ', res.data.data.length,  JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    total.value = res.data.description.rowCount
                    
                    let tmp = []
                    tmp = res.data.data

                    for (var i = 0; i < tmp.length; i++) {
                        arrDgItem.value.push(tmp[i])
                    }

                    totalShown.value = arrDgItem.value.length
                    arrDgItem.value = arrDgItem.value

                } else {
                    func.addLog('BO:userKycList', 'getList', res)
                }
                    
            })
            .catch((error) => {
                func.addLog('BO:userKycList', 'getList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            })

        }

        // Get initial list, without search criteria & rcd limit to decide dgMode
        const getListIni = async () => {
            // console.info('- - func: getListIni')
            isLoading.value = true

            const p = {
                status: 'SUBMITTED,PROCESS,APPROVED,REJECTED',
                orderBy: {
                    field: 'dateCreate',
                    order: 'asc'
                }
            }

            // console.info('- - - getListIni p', JSON.stringify(p))

            let datagridMode = dgMode.value 
            await axios.post('/report/su/user/kyc/list', p)
            .then((res) => {
                // console.info('- - - getListIni RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    // console.info('- - - getListIni RES', 'count', res.data.description.rowCount)
                    total.value = res.data.description.rowCount
                    // console.info('- - - getListIni total', total.value)

                    if (total.value >= dgCutOffRcd.value) {
                        // console.info('- - - part', res.data.data.length)
                        datagridMode = 'part'
                        arrItem.value = res.data.data
                        proDgAllData('part') // set arrDgItem to get partial data fr arrItem(full, no search criteria & rcd limit)
                        // arrItem.value = arrDgItem.value  // Set back arrItem same as arrDgItem for 
                    } 
                    else 
                    {
                        // console.info('- - - all', JSON.stringify(res.data.data))
                        datagridMode = 'all'
                        arrItem.value = res.data.data
                        dgAllFilter() // filter date range
                        proDgAllData()
                    }
                } else {
                    func.addLog('BO:userKycList', 'getListIni', res)
                }

                isLoading.value = false
            })
            .catch((error) => {

                func.addLog('BO:userKycList', 'getListIni - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            })

            dgMode.value = datagridMode
        }

        const iniDg = () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrItem.value = []
            arrDgItem.value = []

            getListIni()
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('- - handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    // console.info('- handleScroll', 'tmp', tmp, '<', 'total', total.value,'| rcdFr PRE', rcdFr.value)
                    if (tmp < total.value) {
                        
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value
                        // console.info('- - - - - handleScroll load more | rcdFr POST', rcdFr.value)
                        if (dgMode.value === 'all') {

                            proDgAllData()
                            isLoadingMore.value = false
                        }
                        else
                        {
                            getList()
                            isLoadingMore.value = false
                        }
                    }
                }
            }
        }

        const goProfile = (userId, wlId, entityId) => {
            router.push({path: '/backoffice/kyc/' + userId, query: {u: userId, w: wlId, e: entityId }})
        } 

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)

            dateFr.value = func.convDateTimeFormat(dateFr.value, 'date')
            dateTo.value = func.convDateTimeFormat(dateTo.value, 'date')

            iniDg()  // date range is compulsory even initial load
             
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
           
        })

        return {
            alert, closeAlert, route, router, func, store, scrollComponent, 
            dgMode, dgCutOffRcd, total, totalShown, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            arrItem, arrDgItem, arrFilterItem, filterName, total, totalShown,
            filterStatus, dateFr, dateTo, totalFilter, 
            clsAvatar, clsCheckbox, checkedUser, goProfile,
        }
    }
}
</script>

<style>

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}

.dp__input {
    font-size: 14px;
}
</style>